import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Form, Button, Alert } from 'antd';
import { Localize } from 'components/service';
import { errorNotification, successNotification } from "components/request-result";
import { CRAFT_CREATE } from "graphql/mutation/craft-gql";
import Stands from 'components/stands';

const CraftAddPrintForm = ({
    action,
    propsObj: {
        query,
        event
    } }) => {

    const [formError, setFormError] = useState(null);
    const [ form ] = Form.useForm();
    const [ _setMutation, { loading: loadingMutation } ] = useMutation(CRAFT_CREATE,
        {
            refetchQueries: [ query ],

            update(cache, { data }) {
                const {
                    craftCreate: {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
            },
            onError(error) {
                setFormError(error.message);
                errorNotification(error);
            }
        }
    );

    const handleSubmit = (values) => {
        if (!values.stand_id || values.stand_id.length === 0) {
            setFormError('Please select at least one booth');
            return;
        }

        setFormError(null);

        // Create an array of promises for each selected stand
        const createPromises = values.stand_id.map(standId =>
            _setMutation({
                variables: {
                    input: {
                        stand_id: standId,
                        type: "collect_graphics",
                        status: "open"
                    }
                }
            })
        );

        // Performing all mutations in parallel
        Promise.all(createPromises)
            .then(() => {
                action();
            })
            .catch(error => {
                setFormError(error.message);
                errorNotification(error);
            });
    };

    return (
        <Form
            key="add-print-form"
            layout="vertical"
            className="add-print-form"
            form={ form }
            onFinish={ handleSubmit }
        >
            {formError && (
                <Alert
                    description={formError}
                    type="error"
                    showIcon
                    style={{ marginBottom: 16 }}
                    closable
                    onClose={() => setFormError(null)}
                />
            )}

            <Stands.Forms.Fields.StandSelect
                form={ form }
                ee_id={ event?.id }
            />

            <div className="form-btn-holder">
                <Button
                    className="ant-btn ant-btn-secondary bg-light-gray"
                    onClick={ action }
                >
                    <Localize>GLOBAL.Button_Text_Cancel</Localize>
                </Button>

                <Button
                    className="btn-right"
                    type="primary"
                    htmlType="submit"
                    loading={ loadingMutation }
                >
                    <Localize>GLOBAL.Button_Text_Confirm</Localize>
                </Button>
            </div>
        </Form>
    );
};

export default CraftAddPrintForm;