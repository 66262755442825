import CraftConst from '../craft-const';
import Icons from 'components/icons';
import { Localize } from 'components/service';


function getIconCraft(type) {

    switch (type.toLowerCase())
    {
        case 'logistics':
        case 'logistics 2':
        case 'logistics 3':
            return <Icons.Crafts.Logistics />;

        case 'floor':
        case 'floor 2':
        case 'floor 3':
            return <Icons.Crafts.Floor />;

        case 'assembly':
        case 'assembly 2':
        case 'assembly 3':
            return <Icons.Crafts.Assembly />;

        case 'electrics':
        case 'electrics 2':
        case 'electrics 3':
            return <Icons.Crafts.Electrics />;

        case 'graphic':
        case 'graphic 2':
        case 'graphic 3':
            return <Icons.Crafts.Graphic />;

        case 'furniture':
        case 'furniture 2':
        case 'furniture 3':
            return <Icons.Crafts.Furniture />;

        case 'multimedia':
        case 'multimedia 2':
        case 'multimedia 3':
            return <Icons.Crafts.Multimedia />;

        case 'cleaning':
        case 'cleaning 2':
        case 'cleaning 3':
            return <Icons.Crafts.Cleaning />;

        case 'additional':
        case 'additional 2':
        case 'additional 3':
            return <Icons.Crafts.Additional />;

        case 'mp control':
            return <Icons.Crafts.MPControl />;

        case 'protocol':
            return <Icons.Crafts.Protocol />;

        case 'service':
        case 'service 2':
        case 'service 3':
            return <Icons.Crafts.Service />;

        case 'collect graphics':
        case 'collect graphics 2':
        case 'collect graphics 3':
            return <Icons.Crafts.CollectGraphicsFilled />;

        default:
            return '';
    }
}


function getTranslationCraft(type) {


    const count = type?.match(/\d+/) && type?.match(/\d+/).length > 0 ? type?.match(/\d+/)[ 0 ] : ''


    switch (type)
    {
        case 'logistics':
        case 'logistics 2':
        case 'logistics 3':
            return <Localize num={ count }>CRAFTS.label_Logistics</Localize>;

        case 'floor':
        case 'floor 2':
        case 'floor 3':
            return <Localize num={ count }>CRAFTS.label_Floor</Localize>;

        case 'assembly':
        case 'assembly 2':
        case 'assembly 3':
            return <Localize num={ count }>CRAFTS.label_Assembly</Localize>;

        case 'electrics':
        case 'electrics 2':
        case 'electrics 3':
            return <Localize num={ count }>CRAFTS.label_Electrics</Localize>;

        case 'graphic':
        case 'graphic 2':
        case 'graphic 3':
            return <Localize num={ count }>CRAFTS.label_Graphic</Localize>;

        case 'furniture':
        case 'furniture 2':
        case 'furniture 3':
            return <Localize num={ count }>CRAFTS.label_Furniture</Localize>;

        case 'multimedia':
        case 'multimedia 2':
        case 'multimedia 3':
            return <Localize num={ count }>CRAFTS.label_Multimedia</Localize>;

        case 'cleaning':
        case 'cleaning 2':
        case 'cleaning 3':
            return <Localize num={ count }>CRAFTS.label_Cleaning</Localize>;

        case 'additional':
        case 'additional 2':
        case 'additional 3':
            return <Localize num={ count }>CRAFTS.label_Additional</Localize>;

        case 'mp control':
            return <Localize>CRAFTS.label_MpControl</Localize>;

        case 'protocol':
            return <Localize>CRAFTS.label_Protocol</Localize>;

        case 'service':
        case 'service 2':
        case 'service 3':
            return <Localize num={ count }>CRAFTS.label_Service</Localize>;

        case 'collect graphics':
        case 'collect graphics 2':
        case 'collect graphics 3':
            return <Localize num={ count }>CRAFTS.label_CollectGraphics</Localize>;

        default:
            return '';
    }
}


function craftColor(status) {
    switch (status)
    {
        case 'completed':
        case 'done':
            return 'color-green';

        case 'in_progress':
            return 'color-yellow';

        case 'open':
            return 'color-gray';

        case 'overdue':
        case 'collected':
            return 'color-red';

        case 'disabled':
            return 'color-disabled';

        default:
            return '';
    }
}


function getCraftTitle(craftType) {
    const craft = CraftConst.craftTypes.find(craft => craft.type === craftType);
    return craft ? craft.title : craftType;
}


const craftFuncHelper = {
    getIconCraft,
    getTranslationCraft,
    craftColor,
    getCraftTitle,
};

export default craftFuncHelper;