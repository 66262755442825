import React, { useState } from "react";
import { Input, Button, Space } from 'antd';
import { useMutation } from "@apollo/client";
import { STAND_UPDATE } from "graphql/mutation/stand-gql";
import Icons from "components/icons";
import Craft from 'components/craft';


const StandShelfNumberField = ({ id, value, craftData, standId }) => {
    const [ shelfNumber, setShelfNumber ] = useState(value);
    const [ standUpdate ] = useMutation(STAND_UPDATE);

    const effectiveStandId = standId || id;

    const craftCreateUpdate = craftData ?
        Craft.Hooks.createUpdate({ craftID: craftData?.id ? true : false }) :
        { _setCraftCreateUpdate: null };

    const { _setCraftCreateUpdate } = craftCreateUpdate;

    const checkboxColor = shelfNumber && shelfNumber.length > 0 ? 'color-green' : 'color-gray';

    const handleChange = (e) => {
        setShelfNumber(e.target.value);
    };

    const handleSubmit = () => {
        const isShelfNumberChanged = shelfNumber !== value;

        if (isShelfNumberChanged)
        {
            standUpdate({
                variables: {
                    input: {
                        id,
                        shelf_number: shelfNumber
                    }
                },
                onCompleted: () => {
                    const hasValidCraft = craftData && _setCraftCreateUpdate;

                    if (hasValidCraft)
                    {
                        const isShelfNumberEmpty = !shelfNumber || shelfNumber.trim() === '';

                        // Определяем новый статус в зависимости от текущего и наличия номера полки
                        let newStatus;

                        if (isShelfNumberEmpty) {
                            // Если номер полки пустой, возвращаем в 'open'
                            newStatus = (craftData.status === 'done' || craftData.status === 'collected') ? 'open' : craftData.status;
                        } else {
                            // Если номер полки заполнен, устанавливаем 'done'
                            // Это означает, что графика проверена и размещена на хранение
                            newStatus = 'done';
                        }

                        _setCraftCreateUpdate({
                            variables: {
                                input: {
                                    id: craftData?.id ? +craftData.id : undefined,
                                    stand_id: +effectiveStandId,
                                    status: newStatus,
                                }
                            }
                        });
                    }
                }
            });
        }
    };

    const handleBlur = () => {
        if (shelfNumber !== value)
        {
            handleSubmit();
        }
    };

    return (
        <Space
            style={ {
                display: 'flex',
                width: '105px',
                overflow: 'hidden'
            } }
            size={ 0 }>
            <Input
                autoFocus
                size="small"
                value={ shelfNumber }
                onChange={ handleChange }
                onBlur={ (e) => {
                    const relatedTarget = e.relatedTarget;
                    if (!relatedTarget || !relatedTarget.classList.contains('shelf-submit-btn'))
                    {
                        handleBlur();
                    }
                } }
                onPressEnter={ handleSubmit }
                style={ { borderTopRightRadius: 0, borderBottomRightRadius: 0 } }
            />
            <div>
                <Button
                    className="shelf-submit-btn"
                    size="small"
                    type="primary"
                    icon={ <span className={ checkboxColor }><Icons.CheckBox /> </span> }
                    onClick={ handleSubmit }
                    style={ {
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        background: '#ffffff',
                        borderColor: '#d9d9d9'
                    } }
                />
            </div>
        </Space>
    );
}

export default StandShelfNumberField;