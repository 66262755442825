import React from 'react';
import { Tooltip } from 'antd';
import Craft from 'components/craft';
import { helperFunc } from 'utils';
import { Localize } from 'components/service';

const StandGraphicCraftField = ({ craftData, standId }) => {

    if (!craftData)
    {
        return <strong>&mdash;</strong>;
    }

    const { charSplitToUpper } = helperFunc;
    const { craftColor, getIconCraft } = Craft.Helpers.Functions;

    const {
        _setCraftCreateUpdate,
    } = Craft.Hooks.createUpdate({ craftID: true });

    const handleCraftClick = () => {
        // Определяем следующий статус в зависимости от текущего
        let nextStatus;
        switch (craftData?.status) {
            case 'open':
                nextStatus = 'collected';
                break;
            case 'collected':
                nextStatus = 'done';
                break;
            case 'done':
                nextStatus = 'open';
                break;
            default:
                nextStatus = 'open';
        }

        _setCraftCreateUpdate({
            variables: {
                input: {
                    id: craftData?.id ? +craftData?.id : undefined,
                    stand_id: +standId,
                    status: nextStatus,
                }
            }
        });
    };

    // Получаем текст для подсказки в зависимости от статуса
    const getTooltipText = () => {
        switch (craftData?.status) {
            case 'open':
                return <Localize>ACTIONS.List_Item_Label_Open</Localize>;
            case 'collected':
                return <Localize>ACTIONS.List_Item_Label_Collected</Localize>;
            case 'done':
                return <Localize>ACTIONS.List_Item_Label_Done</Localize>;
            default:
                return <Localize>ACTIONS.List_Item_Label_Open</Localize>;
        }
    };

    return (
        <div className='graphic-craft-holder'>
            <Tooltip overlayClassName="craft-tooltip" destroyTooltipOnHide={ true } title={ getTooltipText() }>
                <span
                    className={ `craft ${ craftColor(craftData?.status) }` }
                    onClick={ handleCraftClick }
                >
                    { getIconCraft(charSplitToUpper(craftData?.type, '_', false)) }
                </span>
            </Tooltip>
        </div>
    );
};

export default StandGraphicCraftField;